const ContentContainer = ({
  children,
  noPx,
}: {
  children: JSX.Element;
  noPx?: boolean;
}) => {
  return (
    <div className="flex flex-row items-center justify-center">
      <div className={`w-full max-w-screen-lg ${noPx ? "" : "px-4"}`}>
        {children}
      </div>
    </div>
  );
};

export default ContentContainer;
