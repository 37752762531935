"use client";

import Logo from "./logo.svg";
import LogoUcall from "./logo_ucall.png";

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
} from "@nextui-org/react";
import Image from "next/image";
import {
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/navbar";
import useDisclosure from "@/hooks/shared/useDisclosure";
import { useScrollYPosition } from "react-use-scroll-position";
import { usePathname } from "next/navigation";

const AdahubHeader = ({ logo = "adahub" }: { logo?: "adahub" | "ucall" }) => {
  const scrollY = useScrollYPosition();
  const { isOpen, toggle } = useDisclosure();
  const pathname = usePathname();
  const menuItems = ["Sản phẩm", "Giải pháp", "Bảng giá", "Đánh giá"];

  return (
    <Navbar
      position="sticky"
      isMenuOpen={isOpen}
      onMenuOpenChange={toggle}
      className={!isOpen ? "bg-transparent" : ""}
      isBlurred={isOpen || scrollY > 30}>
      <NavbarContent className="sm:hidden flex-grow-0">
        <NavbarMenuToggle aria-label={isOpen ? "Close menu" : "Open menu"} />
      </NavbarContent>
      <NavbarBrand>
        <Link href={pathname}>
          {logo === "adahub" && <Image src={Logo} alt="adahub logo" />}
          {logo === "ucall" && (
            <Image
              placeholder="blur"
              className="max-w-28"
              src={LogoUcall}
              alt="ucall logo"
            />
          )}
        </Link>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Button
            disableRipple
            href="#product"
            as={Link}
            scroll={false}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Sản phẩm
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Button
            disableRipple
            href="#solutions"
            as={Link}
            scroll={false}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Giải pháp
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Button
            disableRipple
            href="#pricing"
            as={Link}
            scroll={false}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Bảng giá
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Button
            disableRipple
            href="#testimonial"
            as={Link}
            scroll={false}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Đánh giá
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent className="hidden sm:flex" justify="end">
        <NavbarItem>
          <div className="flex flex-row gap-x-2">
            <Button
              className="mr-2 border-adahubSecondary text-adahubSecondary bg-white"
              as={Link}
              color="primary"
              href="https://app.adahub.vn"
              variant="bordered">
              Tạo tài khoản
            </Button>
            <Button
              as={Link}
              color="primary"
              href="#contact"
              scroll={false}
              variant="solid"
              className="bg-adahubSecondary">
              Tư vấn tôi
            </Button>
          </div>
        </NavbarItem>

        <NavbarMenu className="bg-transparent backdrop-blur-xl rounded-b-sm">
          <div className="flex-grow-1">
            {menuItems.map((item, index) => (
              <NavbarMenuItem key={`${item}-${index}`}>
                <Button
                  as={Link}
                  color="primary"
                  href="#"
                  scroll={false}
                  variant="ghost"
                  size="lg"
                  className="w-full mb-2">
                  {item}
                </Button>
              </NavbarMenuItem>
            ))}
          </div>

          <div className="flex flex-column flex-wrap gap-y-3 mb-16 text-white">
            <Button
              className="w-full"
              as={Link}
              color="primary"
              href="#"
              variant="shadow">
              Tạo tài khoản
            </Button>
            <Button
              as={Link}
              color="default"
              className="w-full"
              href="#contact"
              scroll={false}
              variant="solid">
              Tư vấn tôi
            </Button>
          </div>
        </NavbarMenu>
      </NavbarContent>
    </Navbar>
  );
};

export default AdahubHeader;
