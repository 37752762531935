"use client";

import { Card, CardBody, CardHeader } from "@nextui-org/react";
import Image from "next/image";
import React, { useState } from "react";
import Image115 from "./image 115.png";
import Image116 from "./image 116.png";
import Image117 from "./image 117.png";
import Image118 from "./image 118.png";
import Image119 from "./image 119.png";
import Image120 from "./image 120.png";
import Image122 from "./image 122.png";
import Image123 from "./image 123.png";
import Ulead from "./Ulead.png";
import ContentContainer from "../ContentContainer";

const features = [
  {
    id: "crm",
    featureTitle: "CRM",
    filePath: Image115,
  },
  {
    id: "tong_dai",
    featureTitle: "Tổng đài",
    filePath: Image116,
  },
  {
    id: "da_kenh",
    featureTitle: "Đa kênh",
    filePath: Image117,
  },
  {
    id: "callbot_ai",
    featureTitle: "Callbot AI",
    filePath: Image118,
  },
  {
    id: "telesales",
    featureTitle: "Telesales",
    filePath: Image119,
  },
  {
    id: "nhan_tin",
    featureTitle: "Nhắn tin",
    filePath: Image120,
  },
  {
    id: "uflow",
    featureTitle: "UFlow",
    filePath: Image122,
  },
  {
    id: "email_marketing",
    featureTitle: "Email Marketing",
    filePath: Image123,
  },
  {
    id: "ulead",
    featureTitle: "ULead",
    filePath: Ulead,
  },
];

const Features = () => {
  const [selected, setSelected] = useState();
  return (
    <div className="full-size flex flex-column items-center justify-center mt-9 mb-3">
      <ContentContainer>
        <div className="text-center">
          <div className="text-lg mb-6">
            Khám phá nhiều sản phẩm hơn của UCALL
          </div>
          <h3 className="font-semibold text-3xl text-adahubSecondary">
            Giải phóng đội ngũ kinh doanh của bạn
            <br />
            với cụm chức năng của UCALL
          </h3>
        </div>
      </ContentContainer>
      <div className="flex flex-row mt-6 w-full items-center justify-center">
        <div className="flex flex-row max-w-[1100px] overflow-x-scroll">
          {features.map((e) => (
            <Card
              isHoverable
              isPressable
              key={`e.${e.id}`}
              shadow="none"
              className="py-4 w-full min-w-[120px]">
              <CardBody className="overflow-visible flex-col justify-center items-center py-2">
                <Image
                  placeholder="blur"
                  alt="Card background"
                  className="object-fit-contain"
                  src={e.filePath}
                  height={50}
                />
              </CardBody>
              <CardHeader className="pb-0 pt-2 px-4 flex-col justify-center items-center h-[64px]">
                <h4 className="font-bold text-sm">{e.featureTitle}</h4>
              </CardHeader>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
