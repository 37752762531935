"use client";

import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { SubmitHandler, useForm } from "react-hook-form";
import Image from "next/image";
import BackgroundImage from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Banner2/background.png";

export const industries = [
  { key: "crm_ban_hang", label: "CRM Bán hàng" },
  { key: "chat_da_kenh", label: "Chat đa kênh" },
  { key: "tong_dai_ao", label: "Tổng đài ảo" },
  { key: "callbot_ai_ucall", label: "Callbot AI Ucall" },
  { key: "chien_dich_tin_nhan", label: "Chiến dịch tin nhắn" },
  { key: "chien_dich_telesales", label: "Chiến dịch Telesales" },
  { key: "nen_tang_data_adalead", label: "Nền tảng data AdaLead" },
  { key: "tu_dong_hoa_adaflow", label: "Tự động hóa AdaFlow" },
  { key: "email_marketing", label: "Email Marketing" },
];

const inputProps = {
  variant: "bordered" as const,
  classNames: {
    inputWrapper: "bg-white shadow-none",
  },
  labelPlacement: "outside" as const,
  isClearable: true,
};

export type Inputs = {
  name: string;
  phone: string;
  product: string;
  email: string;
  note: string;
};

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <div className="flex flex-col gap-6">
        <Input
          key={"name"}
          type="text"
          label="Họ và tên"
          placeholder="Nhập họ và tên của bạn"
          {...inputProps}
          {...register("name")}
        />
        <Input
          key={"phone"}
          type="phone"
          isRequired
          label="Số điện thoại"
          placeholder="Nhập số điện thoại của bạn"
          {...inputProps}
          {...register("phone", { required: true })}
          {...(errors.phone
            ? {
                isInvalid: true,
                errorMessage: "Vui lòng nhập số điện thoại",
              }
            : {})}
        />
        <Input
          key={"email"}
          type="email"
          label="Email"
          placeholder="Nhập địa chỉ email của bạn"
          {...inputProps}
          {...register("email")}
        />

        <Select
          label="Bạn quan tâm đến sản phẩm nào?"
          placeholder="Lựa chọn sản phẩm bạn quan tâm"
          labelPlacement="outside"
          scrollShadowProps={{
            isEnabled: false,
          }}
          {...inputProps}
          classNames={{
            mainWrapper: "bg-white shadow-none rounded-2xl",
            trigger: "shadow-none",
          }}
          {...register("product")}>
          {industries.map((e) => (
            <SelectItem key={e.key}>{e.label}</SelectItem>
          ))}
        </Select>

        <Input
          key={"note"}
          type="text"
          label="Ghi chú"
          placeholder="Nhập nội dung ghi chú"
          {...inputProps}
          {...register("note")}
        />
      </div>
      <div className="flex flex-row items-center justify-center">
        <Button
          type="submit"
          className="mt-10 bg-adahubSecondary text-base w-40 rounded-3xl h-14"
          color="primary">
          Gửi thông tin
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
